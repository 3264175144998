<template>
  <div class="technologyBox">
    <mobileHeader />
    <div class="techbg">
      <img src="../../assets/m_techobg.jpg" />
      <div
        class="banner-title"
        :class="{ bannerTitleB: $i18n.locale === 'en-US' }"
      >
        {{ $t("lang.technologyCenter.bannerTitle") }}
      </div>
    </div>
    <div class="techbox">
      <div class="title">{{ $t("lang.technologyCenter.titles") }}</div>
      <div class="solveWay">
        <div class="solvebox">
          <div class="solveTitle">
            <img src="../../assets/con-img1-1.png" />
            <div class="item-title">
              <div class="titles">解决方案</div>
              <span>IT SOLUTION</span>
            </div>
          </div>
          <div class="solveList">
            <div class="part">
              <div class="pic">
                <img src="../../assets/icon7-1.png" />
              </div>
              <div>基础网络</div>
              <span>NETWORK</span>
            </div>
            <div class="part">
              <div class="pic">
                <img src="../../assets/icon8-1.png" />
              </div>
              <div>数据中心</div>
              <span>DATACENTER</span>
            </div>
            <div class="part">
              <div class="pic">
                <img src="../../assets/icon9-1.png" />
              </div>
              <div>安全优化</div>
              <span>SECURITY</span><span>OPTIMIZATION</span>
            </div>
            <div class="part">
              <div class="pic">
                <img src="../../assets/icon10-1.png" />
              </div>
              <div>视频协作</div>
              <span>COLLABORATION</span>
            </div>
            <div class="part">
              <div class="pic">
                <img src="../../assets/icon11-1.png" />
              </div>
              <div>机房弱电</div>
              <span>CABLING</span>
            </div>
            <div class="part">
              <div class="pic">
                <img src="../../assets/icon12-1.png" />
              </div>
              <div>无线增值</div>
              <span>WIRELESS</span>
            </div>
          </div>
        </div>
      </div>
      <div class="solveWays">
        <div class="solvebox">
          <div class="solveTitle">
            <img src="../../assets/con-img-1.png" class="hideImg" />
            <div class="item-title">
              <div class="titles">软件集成</div>
              <span>SOFTWARE INTEGRATION</span>
            </div>
          </div>
          <div class="solveList">
            <div class="part">
              <div class="pic">
                <img src="../../assets/icon1-1.png" class="hideImg" />
              </div>
              <div>协作办公</div>
              <span>COOPERATION</span>
            </div>
            <div class="part">
              <div class="pic">
                <img src="../../assets/icon2-1.png" class="hideImg" />
              </div>
              <div>数据挖掘</div>
              <span>DATA MINING</span>
            </div>
            <div class="part">
              <div class="pic">
                <img src="../../assets/icon3-1.png" class="hideImg" />
              </div>
              <div>私有云</div>
              <span>PRIVATE CLOUD</span>
            </div>
            <div class="part">
              <div class="pic">
                <img src="../../assets/icon4-1.png" class="hideImg" />
              </div>
              <div>成绩云</div>
              <span>RESULTS CLOUD</span>
            </div>
            <div class="part">
              <div class="pic">
                <img src="../../assets/icon5-1.png" class="hideImg" />
              </div>
              <div>应用与网络管理</div>
              <span>APM</span>
            </div>
            <div class="part">
              <div class="pic">
                <img src="../../assets/icon6-1.png" class="hideImg" />
              </div>
              <div>无线增值</div>
              <span>WIRELESS</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mobileFooter />
  </div>
</template>

<script>
import mobileHeader from "@/components/mobileHeader";
import mobileFooter from "@/components/mobileFooter";
import AOS from "aos";
export default {
  name: "technologyCenter",
  components: {
    mobileHeader,
    mobileFooter,
  },
  mounted() {
    AOS.init({
      duration: 1200,
    });
  },
};
</script>

<style lang="scss" scoped>
.technologyBox {
  .techbg {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 30px;
      color: #fff;
      font-weight: bold;
    }
    .bannerTitleB {
      font-family: "English-Bold", serif;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .techbox {
    margin: 0 auto;
    //margin-top: 120px;
    .title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin: 50px 0;
      margin-bottom: 45px;
    }
    .solveWay {
      background: url("../../assets/m_tebg.jpg") repeat center center;
      padding-bottom: 50px;
    }
    .solvebox {
      display: flex;
      padding: 0 25px;
      flex-direction: column;
      z-index: 9;
      .solveTitle {
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .item-title {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        img {
          padding-top: 40px;
          width: 100px;
        }
        .titles {
          font-size: 17px;
          font-weight: bold;
        }
        span {
          font-size: 10px;
          font-weight: 500;
        }
      }
    }
    .solveWays {
      background: url("../../assets/m_tebg.jpg") repeat center center;
      cursor: pointer;
      margin-top: 40px;
      padding-bottom: 50px;
      .part {
        div,
        span {
        }
      }
      .solveTitle {
        color: #17518c;
        .item-title {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #fff;
        }
      }
      .hideImg {
        display: block;
      }
      .showImg {
        display: block;
      }
    }

    .solveList {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .part {
        width: calc((100% - 25px) / 2);
        background: #ffffff;
        border-radius: 5px;
        height: 214px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin-top: 30px;
        justify-content: center;
        .pic {
          margin-top: 30px;
          height: 77px;
        }
        div {
          font-size: 14px;
          font-weight: bold;
          color: #17518c;
          margin-top: 12px;
        }
        span {
          color: #b0b0b1;
          font-weight: bold;
          font-size: 12px;
        }
      }
      .part:nth-child(1),
      .part:nth-child(2) {
        margin-top: 20px;
      }
    }
  }
  .part:hover {
    animation: rotateFn 1s infinite;
    -webkit-animation: rotateFn 1s infinite;
  }
  @keyframes rotateFn {
    0% {
      transform: perspective(400px) rotateY(0deg);
      -webkit-transform: perspective(400px) rotateY(0deg);
    }
    100% {
      transform: perspective(400px) rotateY(360deg);
      -webkit-transform: perspective(400px) rotateY(360deg);
    }
  }
}
</style>
